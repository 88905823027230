import { Card, Margin, Small, P, Notification } from '@ovotech/nebula';
import {
  AnalyticsClick,
  useAccountContext,
  useAnalytics,
} from '@ovotech/ui-tools';
import { getDaysInMonth } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Media } from '@/src/components/Media';
import { useSelector } from 'react-redux';

import { useLocation, matchPath, useParams } from 'react-router-dom-v5';
import {
  Bar,
  CartesianGrid,
  BarChart,
  Label,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  LegendType,
} from 'recharts';
import {
  getDateFromParams,
  checkMissingBreakdownData,
  getAverageBreakdownValue,
  isMissingAnyRequestedMonthAggregateData,
} from '../../utils';
import { CollectingSmartChargerData } from '../EVOnboarding/CollectingSmartChargerData';
import { MonthlySummary } from '../MonthlySummary/MonthlySummary';
import CustomTooltip from './ChartCustomTooltip';
import {
  StyledGraph,
  StyledGraphCard,
  StyledText,
  StyledToggleButton,
  StyledToggleButtonContent,
  StyledToggleButtonLabel,
  ToggleContainer,
  ToggleTextContainer,
  ToggleTextItem,
  UsageUnitText,
  VerticalSeparator,
} from './ElectricUsageChart.styled';
import { useKaluzaMonthlyChargerDataQuery } from '@/src/api';
import { State, MonthYear, EVsInlifeDataSeries } from '@/src/types/State';

import theme from '@/src/core/theme';
import { ReactComponent as ChevronDown } from '@/src/resources/chevron-down.svg';
import { ReactComponent as ChevronUp } from '@/src/resources/chevron-up.svg';

import { breakpoints } from '@/src/utils/mediaQuery';

import {
  INDRA_SUPPORT_EMAIL,
  INDRA_SUPPORT_PHONE,
  OHME_SUPPORT_EMAIL,
  OHME_SUPPORT_PHONE,
} from '@/src/constants/routes';
import { isStartOfMonth, EV } from '@monovo/ev-anytime';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

const usageUnitMap: {
  [index: string]: 'kwh' | 'pounds';
} = {
  KWH: 'kwh',
  POUNDS: 'pounds',
};

type Props = {
  dateNavigatedTo: MonthYear;
};

const FNS_MONTH_INDEX_CORRECTION = 1;
const convertMonthYearToDateObj = (date: MonthYear) =>
  new Date(date.year, date.month - FNS_MONTH_INDEX_CORRECTION, 1);

const formatResponse = (dailyBreakdown: EVsInlifeDataSeries) => {
  const averageCostCar = getAverageBreakdownValue(
    dailyBreakdown,
    'cost',
    'electricVehicle',
  );
  const averageCostHome = getAverageBreakdownValue(
    dailyBreakdown,
    'cost',
    'home',
  );
  const averageKWhCar = getAverageBreakdownValue(
    dailyBreakdown,
    'usageInKWH',
    'electricVehicle',
  );
  const averageKWhHome = getAverageBreakdownValue(
    dailyBreakdown,
    'usageInKWH',
    'home',
  );

  return dailyBreakdown.map(day => {
    return {
      dayOfMonth: day.dayOfMonth,
      electricityCarInPounds: day.electricVehicle.cost,
      electricityCarInKWH: day.electricVehicle.usageInKWH,
      electricityHomeInPounds: day.home.cost,
      electricityHomeInKWH: day.home.usageInKWH,
      // The following values are only filled for days with missing data, when an average value is used
      averageCostCar: day.electricVehicle.cost === null ? averageCostCar : 0,
      averageCostHome: day.home.cost === null ? averageCostHome : 0,
      averageKWhCar:
        day.electricVehicle.usageInKWH === null ? averageKWhCar : 0,
      averageKWhHome: day.home.usageInKWH === null ? averageKWhHome : 0,
    };
  });
};

type ToggleProps = {
  setUsageUnit: (val: 'kwh' | 'pounds') => void;
  usageUnit: 'kwh' | 'pounds';
};

const Toggle = ({ setUsageUnit, usageUnit }: ToggleProps) => (
  <ToggleContainer>
    <ToggleTextContainer>
      <ToggleTextItem>
        <UsageUnitText
          data-testid="usage-unit-toggle-pounds"
          aria-disabled="false"
          aria-pressed={usageUnit === usageUnitMap.POUNDS}
          isButton
          onClick={() => {
            setUsageUnit(usageUnitMap.POUNDS);
          }}
        >
          Cost
        </UsageUnitText>
      </ToggleTextItem>
      <VerticalSeparator />
      <ToggleTextItem>
        <UsageUnitText
          data-testid="usage-unit-toggle-kwh"
          aria-disabled="false"
          aria-pressed={usageUnit === usageUnitMap.KWH}
          isButton
          onClick={() => {
            setUsageUnit(usageUnitMap.KWH);
          }}
        >
          kWh
        </UsageUnitText>
      </ToggleTextItem>
    </ToggleTextContainer>
  </ToggleContainer>
);

export const ElectricUsageChartMonthly = ({ dateNavigatedTo }: Props) => {
  const { dispatch } = useAnalytics();
  const { connection } = useSelector(({ evInlife }: State) => evInlife);
  const { accountId } = useAccountContext();
  const { anytimeBoltonActive, anytimeBolton } = useChargeAnytimeBoltonState();
  const monthlyDataResponse = useKaluzaMonthlyChargerDataQuery(
    connection,
    dateNavigatedTo,
    accountId,
    anytimeBolton?.activeFrom,
  );
  const monthlyData =
    monthlyDataResponse.status === 'data' ? monthlyDataResponse.data : null;
  const [usageUnit, setUsageUnit] = useState(usageUnitMap.POUNDS);
  const [focusBar, setFocusBar] = useState<number | undefined | null>(null);
  const [showGraph, setShowGraph] = useState(false);
  const dateObj = convertMonthYearToDateObj(dateNavigatedTo);
  const { year, month } = useParams<{ year: string; month: string }>();
  const date = getDateFromParams(month, year);
  const location = useLocation();

  useEffect(() => {
    if (monthlyDataResponse.status === 'error') {
      dispatch({
        type: 'render',
        name: EV.INLIFE.EVS_INLIFE_MONTHLY_ERROR,
      });
    }
    if (
      monthlyData &&
      isMissingAnyRequestedMonthAggregateData(
        monthlyData.monthlySummary.requestedMonth,
        usageUnit,
      )
    ) {
      dispatch({
        type: 'render',
        name: EV.INLIFE.EVS_INLIFE_MONTHLY_MISSING_DATA(usageUnit),
      });
    }

    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyData, monthlyDataResponse.status, usageUnit]);

  const viewsToRender = [
    {
      title: 'Year',
      path: `/my-ev/:year(\\d{4})`,
      selectPath: `/my-ev/${dateObj.getFullYear()}`,
    },
    {
      title: 'Month',
      path: `/my-ev/:year(\\d{4})/:month(\\d{2})`,
      selectPath: `/my-ev/${dateObj.getFullYear()}/${
        dateObj.getMonth() + FNS_MONTH_INDEX_CORRECTION
      }`,
    },
  ];

  const index = viewsToRender.findIndex(({ path }) =>
    matchPath(location.pathname, { path, exact: true }),
  );
  const showValuesInPounds = usageUnit === usageUnitMap.POUNDS;

  if (monthlyDataResponse.status !== 'data' && monthlyDataResponse.isFetching) {
    return <CollectingSmartChargerData />;
  }

  const mailToLink = (email: string) => {
    return (
      <a
        href={`mailto
      :${email}`}
      >
        {email}
      </a>
    );
  };

  const MonthlyDataError = () => {
    if (isStartOfMonth()) {
      return (
        <Card>
          <Toggle usageUnit={usageUnit} setUsageUnit={setUsageUnit} />
          <Margin top={4}>
            <Notification
              id="missing-month-notification"
              data-testid="missing-monthly-data-notification"
              title="Your charger data isn't showing right now"
              variant="info"
            >
              At the start of each month, it can take a couple of days for your
              data to pull through.
              <p />
              Please try again later.
            </Notification>
          </Margin>
        </Card>
      );
    }

    return (
      <Card>
        <Toggle usageUnit={usageUnit} setUsageUnit={setUsageUnit} />
        <Margin top={4}>
          <Notification
            id="missing-month-notification"
            data-testid="missing-monthly-data-notification"
            title="Missing charger data"
            variant="warning"
          >
            If you’ve only just activated Charge Anytime, it can take a few
            charges of your EV before there’s enough data to show. <br /> <br />
            If you’ve been using Charge Anytime for a while, there could be an
            issue with your charger that means it’s stopped sending data to us.
            To fix this, please get in touch with your charger manufacturer:
            <br />
            <br />
            Ohme: {mailToLink(OHME_SUPPORT_EMAIL)} or {OHME_SUPPORT_PHONE}.{' '}
            <br />
            Indra: {mailToLink(INDRA_SUPPORT_EMAIL)} or {INDRA_SUPPORT_PHONE}.
          </Notification>
        </Margin>
      </Card>
    );
  };

  if (monthlyDataResponse.status === 'error') {
    return <MonthlyDataError />;
  }

  if (
    monthlyData &&
    isMissingAnyRequestedMonthAggregateData(
      monthlyData.monthlySummary.requestedMonth,
      usageUnit,
    )
  ) {
    return <MonthlyDataError />;
  }

  if (monthlyData) {
    const formattedResponseForChart = formatResponse(
      monthlyData.dailyBreakdown,
    );
    const hasMissingData = checkMissingBreakdownData(
      monthlyData.dailyBreakdown,
    );
    const legendItems = [
      {
        value: 'EV',
        id: '1',
        type: 'circle' as LegendType,
        color: theme.colors.brand.complementary.base,
      },
      {
        value: 'Home',
        id: '2',
        type: 'circle' as LegendType,
        color: theme.colors.brand.electricity.base,
      },
    ];

    if (hasMissingData) {
      legendItems.push({
        value: 'Missing data',
        id: '3',
        type: 'circle' as LegendType,
        color: theme.colors.brand.grey.base,
      });
    }
    return (
      <>
        {index > -1 && (
          <Card as={StyledGraphCard} data-testid="ev-usage-chart-monthly">
            <Toggle usageUnit={usageUnit} setUsageUnit={setUsageUnit} />
            <MonthlySummary
              usageUnit={usageUnit}
              monthlyData={monthlyData?.monthlySummary}
            />
            {usageUnit === usageUnitMap.POUNDS && (
              <Small>
                <StyledText>
                  ¹ This figure includes only unit rates. It doesn’t include
                  standing charges, VAT or any other costs.
                </StyledText>
              </Small>
            )}

            {!anytimeBoltonActive && (
              <Media query={breakpoints.mediumUp}>
                <StyledGraph isHidden={!showGraph}>
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart
                      height={500}
                      data={formattedResponseForChart}
                      margin={{
                        top: 70,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                      onMouseMove={state => {
                        if (state.isTooltipActive) {
                          setFocusBar(state.activeTooltipIndex);
                        } else {
                          setFocusBar(null);
                        }
                      }}
                    >
                      <CartesianGrid
                        vertical={false}
                        horizontal
                        stroke={'none'}
                      />
                      <XAxis
                        dataKey="dayOfMonth"
                        type="number"
                        axisLine={{
                          stroke: '#F3F3F3',
                        }}
                        padding={{ left: 15, right: 15 }}
                        height={30}
                        interval={0}
                        tickLine={false}
                        tickCount={getDaysInMonth(
                          new Date(date.year, date.month - 1),
                        )}
                        domain={[
                          1,
                          getDaysInMonth(new Date(date.year, date.month - 1)),
                        ]}
                      />
                      {/* @ts-ignore react-18 children props */}
                      <YAxis
                        width={40}
                        orientation="left"
                        axisLine={{
                          stroke: '#F3F3F3',
                        }}
                        tickLine={false}
                        scale="linear"
                        height={50}
                        padding={{
                          top: 32,
                          bottom: 0,
                        }}
                        tickMargin={5}
                      >
                        <Label
                          value={usageUnit === usageUnitMap.KWH ? 'kWh' : '£'}
                          position="insideTopLeft"
                        />
                      </YAxis>
                      <Legend
                        align="left"
                        verticalAlign="bottom"
                        payload={legendItems}
                      />
                      {formattedResponseForChart.map((_, index) => (
                        <Cell
                          key={`cell-${index}`}
                          stroke={
                            focusBar === index
                              ? theme.colors.brand.grey.darkest
                              : undefined
                          }
                          strokeWidth={2}
                        />
                      ))}
                      {/* @ts-ignore react-18 children props */}
                      <Bar
                        dataKey={
                          showValuesInPounds
                            ? 'electricityCarInPounds'
                            : 'electricityCarInKWH'
                        }
                        name={'EV'}
                        fill={theme.colors.brand.complementary.base}
                        fillOpacity="10"
                        shape={<Rectangle radius={[0, 0, 0, 0]} />}
                        barSize={15}
                        isAnimationActive={true}
                        stackId="a"
                        cursor={'pointer'}
                      >
                        {formattedResponseForChart.map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            stroke={
                              focusBar === index
                                ? theme.colors.brand.grey.darkest
                                : undefined
                            }
                            strokeWidth={2}
                          />
                        ))}
                      </Bar>
                      {/* @ts-ignore react-18 children props */}
                      <Bar
                        dataKey={
                          showValuesInPounds
                            ? 'averageCostHome'
                            : 'averageKWhHome'
                        }
                        name={''}
                        fill={theme.colors.brand.grey.base}
                        fillOpacity="10"
                        shape={<Rectangle radius={[0, 0, 0, 0]} />}
                        barSize={15}
                        isAnimationActive={true}
                        stackId="a"
                        cursor={'pointer'}
                      >
                        {formattedResponseForChart.map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            stroke={
                              focusBar === index
                                ? theme.colors.brand.grey.darkest
                                : undefined
                            }
                            strokeWidth={2}
                          />
                        ))}
                      </Bar>
                      {/* @ts-ignore react-18 children props */}
                      <Bar
                        dataKey={
                          showValuesInPounds
                            ? 'averageCostCar'
                            : 'averageKWhCar'
                        }
                        name={'CAR'}
                        fill={theme.colors.brand.grey.base}
                        fillOpacity="10"
                        shape={<Rectangle radius={[0, 0, 0, 0]} />}
                        barSize={15}
                        isAnimationActive={true}
                        stackId="a"
                        cursor={'pointer'}
                      >
                        {formattedResponseForChart.map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            stroke={
                              focusBar === index
                                ? theme.colors.brand.grey.darkest
                                : undefined
                            }
                            strokeWidth={2}
                          />
                        ))}
                      </Bar>
                      {/* @ts-ignore react-18 children props */}
                      <Bar
                        dataKey={
                          showValuesInPounds
                            ? 'electricityHomeInPounds'
                            : 'electricityHomeInKWH'
                        }
                        name={'Home'}
                        fill={theme.colors.brand.electricity.base}
                        fillOpacity="10"
                        shape={<Rectangle radius={[3, 3, 0, 0]} />}
                        barSize={15}
                        isAnimationActive={true}
                        stackId="a"
                        cursor={'pointer'}
                      >
                        {formattedResponseForChart.map((_, index) => (
                          <Cell
                            key={`cell-${index}`}
                            stroke={
                              focusBar === index
                                ? theme.colors.brand.grey.darkest
                                : undefined
                            }
                            strokeWidth={2}
                          />
                        ))}
                      </Bar>

                      <Tooltip
                        content={props => (
                          <CustomTooltip usageUnit={usageUnit} {...props} />
                        )}
                        cursor={false}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                  {hasMissingData && (
                    <Margin top={8}>
                      <Notification
                        dismissable
                        id="missing-data-notification"
                        data-testid="monthly-usage-chart-partially-missing-data"
                      >
                        <P>
                          Some of your data is missing so these figures might
                          not be 100% accurate.
                          <br /> No worries – this won’t affect your monthly
                          bill at all.
                        </P>
                      </Notification>
                    </Margin>
                  )}
                </StyledGraph>
                <Margin vertical={6}>
                  <AnalyticsClick name={EV.INLIFE.EVS_INLIFE_SHOW_MORE_CHART}>
                    <StyledToggleButton
                      onClick={() => setShowGraph(!showGraph)}
                    >
                      <StyledToggleButtonContent data-testid="show-usage-graph">
                        <StyledToggleButtonLabel>
                          {showGraph ? 'Show less' : 'Show more details'}
                        </StyledToggleButtonLabel>
                        <span aria-hidden="true">
                          {showGraph ? <ChevronUp /> : <ChevronDown />}
                        </span>
                      </StyledToggleButtonContent>
                    </StyledToggleButton>
                  </AnalyticsClick>
                </Margin>
              </Media>
            )}
          </Card>
        )}
      </>
    );
  } else {
    return null;
  }
};
