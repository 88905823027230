import {
  Reason,
  StyledSubtitle,
} from '@/src/pages/AnytimeEligibility/pages/Fallout/Fallout.styled';
import React from 'react';
import { Margin, P, TextLink } from '@ovotech/nebula';
import {
  VOLVO_APP_LINK,
  CHARGE_ANYTIME_ANDROID_APP_LINK,
  CHARGE_ANYTIME_IOS_APP_LINK,
  VOLVO_CAR_APP_LINK,
} from '@monovo/ev-anytime';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

const VolvoSubtitle = () => (
  <StyledSubtitle>For Volvo customers</StyledSubtitle>
);

const OtherSubtitle = () => (
  <StyledSubtitle>For all other customers</StyledSubtitle>
);

const BackToHome = () => (
  <Margin top={4}>
    <ReturnToHomePage />
  </Margin>
);

export const FalloutChargeAnytimeInitialized = () => {
  return (
    <Reason data-testid="charge-anytime-initialized">
      <P>
        Good news! There’s no need to check your compatibility again. You can
        get Charge Anytime - you just need to complete your set-up.
      </P>
      <VolvoSubtitle />
      <P>
        To activate Charge Anytime, go to your{' '}
        <TextLink href={VOLVO_APP_LINK} opensInNewWindow>
          Volvo Cars app
        </TextLink>{' '}
        and link your Volvo and OVO accounts. Once you've done this, you'll be
        able to manage your Volvo smart charging directly from your Volvo Cars
        app.
      </P>
      <OtherSubtitle />
      <P>
        You can activate Charge Anytime by setting up smart charging through the
        OVO Charge Anytime app - on{' '}
        <TextLink href={CHARGE_ANYTIME_ANDROID_APP_LINK} opensInNewWindow>
          Android
        </TextLink>{' '}
        or{' '}
        <TextLink href={CHARGE_ANYTIME_IOS_APP_LINK} opensInNewWindow>
          iOS
        </TextLink>
        . Simply access the OVO Charge Anytime app, link with your OVO account,
        and follow the steps to connect your home charger or EV.
      </P>
      <BackToHome />
    </Reason>
  );
};

export const FalloutChargeAnytimeActivated = () => {
  return (
    <Reason data-testid="charge-anytime-activated">
      <P>
        Good news! There’s no need to check your compatibility again. You’re all
        set up.
      </P>
      <VolvoSubtitle />
      <p>
        To manage your Volvo smart charging, simply go to your{' '}
        <TextLink href={VOLVO_CAR_APP_LINK} opensInNewWindow>
          Volvo Cars app
        </TextLink>{' '}
        and start charging.
      </p>

      <OtherSubtitle />
      <P>
        You can smart charge through the OVO Charge Anytime app - on{' '}
        <TextLink href={CHARGE_ANYTIME_ANDROID_APP_LINK} opensInNewWindow>
          Android
        </TextLink>{' '}
        or{' '}
        <TextLink href={CHARGE_ANYTIME_IOS_APP_LINK} opensInNewWindow>
          iOS
        </TextLink>
        .
      </P>
      <BackToHome />
    </Reason>
  );
};

export const FalloutChargeAnytime = () => {
  const { payAsYouDriveBoltonActive, payAsYouDriveBoltonInitialised } =
    useChargeAnytimeBoltonState();
  if (payAsYouDriveBoltonActive) {
    return <FalloutChargeAnytimeActivated />;
  }
  if (payAsYouDriveBoltonInitialised) {
    return <FalloutChargeAnytimeInitialized />;
  }
  return null;
};
